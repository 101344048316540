import * as actionType from "../constants/actionTypes";

const webseriesReducer = (state = { webseriesData: null }, action) => {
  switch (action.type) {
    case actionType.EPISODES:
      return { ...state, episodes: action.payload };
    case actionType.EPISODE:
      return { ...state, episode: action.payload };

    case actionType.SERIES:
      return { ...state, series: action.payload };
    case actionType.EPISODE_WATCH_USER:
      return { ...state, episode_watch_user: action.payload };
    case actionType.SEASON:
      return { ...state, season: action.payload };
    case actionType.SERIESDETAILS:
      return { ...state, series_detail: action.payload };
    case actionType.SERIES_NAME:
      return { ...state, series_name: action.payload };

    default:
      return state;
  }
};

export default webseriesReducer;
