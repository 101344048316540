
import { useSelector } from "react-redux"

export const TableData = () => {
  const rights = useSelector((state) => state.layout.right);
  const role = useSelector((state) => state?.layout?.role);
  return {
    tableTitle: "Episode",
    deleteAccess: rights?.["Episode"]?.["delete"] == "true",
    tableHead: [
      {
        id: 'episode_title',
        label: 'Episode Title',
        link: "/WebEpisode/WebEpisode/EpisodeDetails",
        subText:"episode_number",
        color: "var(--gradientColor2)"
      },
      {
        id: 'series_name',
        label: 'Series Name',
        subText: "season"
      },

      {
        id: 'episode_poster',
        label: 'Image',
        isImage: true
      },


      {
        id: 'release_date',
        label: 'Release Date',
        subText: "publish_time"
      },
      // {
      //   id: 'uploaded_by',
      //   label: 'Uploaded By',
      // },
      // {
      //   id: 'series_ownership',
      //   label: 'Ownership',
      // },
      // {
      //   id: "episodeViews",
      //   label: "Views"
      // }
      ,
      {
        id: 'status',
        label: 'Status',
        isButtonDisplay: true

      },
      role !== "Distributor" && {
        id: 'edit',
        label: 'Update',
        access: rights?.["Episode"]?.["edit"] == "true"
      },
    ],
    tableBody: [
      {
        id: 0,
        series_name: "Movie",
        poster: "Landscape",
        season_name: "2",
        episode_title: "Movie",
        release_date: "Landscape",


        status: "Active",
        edit: 0
      }
    ],
    filterColumn: [
      {
        id: "1",
        title: "Series",
        name: "series_name",
        options: ["Series 1", "Series 2", "Series 3"],
      },
      // {
      //   id: "2",
      //   title: "Season",
      //   name: "season",
      //   // displayOn1:"series_name",
      //   displayOn: "series_name",
      //   dependentField: "series_name",
      //   requredField: "season",
      //   options: ["Season 1", "Season 2", "Season 3"],
      // }
    ]
  }
}