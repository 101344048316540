import * as actionType from "../constants/actionTypes";

const moviesReducer = (state = { moviesData: null }, action) => {
	switch (action.type) {
		
		case actionType.MOVIES:
			return {...state,movies: action.payload};
			case actionType.MOVIE:
			return {...state,movie: action.payload};
			
			case actionType.UPCOMING_MOVIES:
			return {...state,upcoming_movies: action.payload};
			case actionType.MOVIE_WATCH_USER:
			return {...state,movie_watch_user: action.payload};
			case actionType.MOVIE_NAME:
			return {...state,movie_name: action.payload};
	
			
		default:
			return state;
	}
};

export default moviesReducer;
