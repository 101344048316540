import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import ViewChange from "../../utils/ViewChange";
import {
  season_create,
  season_delete,
  season_update,
  all_season_list,
} from "../../../actions/WebSeries/season";
import { all_series_list } from "../../../actions/WebSeries/series";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
export default function Season() {
  const user = useSelector((state) => state.layout.profile);
  const dispatch = useDispatch();
  // const rights = useSelector((state) => state.layout.rights);
  const rights = useSelector((state) => state.layout.right);
  const role = useSelector((state) => state?.layout?.role);
  const location = useLocation();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [flag, setFlag] = useState(false);
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const [view, setView] = useState(location?.state?.view || "view_all");

  const path = location?.pathname.split("/")[2];
  useEffect(() => {
    setView(path != "Season" ? "create_new" : "view_all");
    setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
    setIsEdit(path == "EditSeason");
  }, [location]);
  const tempTableData = {
    tableTitle: "Seasons",
    deleteRecord: season_delete,
    updateRecord: season_update,
    onDeleteText: "Are you sure to delete?",
    disableDelete: role !== "Distributor" ? false : true,
    deleteAccess: rights?.["Season"]?.["delete"] == "true",
    onUpdateText: "Are you Sure?",
    tableHead: [
      {
        id: "series_name",
        numeric: false,
        disablePadding: true,
        label: "Show",
      },
      {
        id: "season",
        label: "Season",
      },
      {
        id: "season_thumbnail",
        label: "Image",
        isImage: true,
      },

      role !== "Distributor" &&{
        id: "edit",
        label: "Update",
        access: rights?.["Season"]?.["edit"] == "true",
      },
    ],
    tableBody: [
    ],
  };
  const [tableData, setTableData] = useState({ ...tempTableData });
  useMemo(() => {
    setTableData({ ...tempTableData });
  }, [rights]);
  // console.log(isEdit,"popo")
  const [formStructure, setFormStructure] = useState([
    {
      id: "1",
      type: isEdit ? "inputBox" :"select",
      title: "Show type",
      name: "series_type",
      options:["Web Series", "Super Short Series"],
      required: true,
      disabled: isEdit ? "true" : "false",
    },
    {
      id: "1",
      type: isEdit ? "inputBox" :"select",
      title:isEdit ?"parth": "Show Name",
      name: "series",
      options: [],
      required: true,
      // disabled: isEdit ? true : false,
    },
    {
      id: "2",
      type: "select",
      title: "Season",
      name: "season",
      options: [
        "Season 1",
        "Season 2",
        "Season 3",
        "Season 4",
        "Season 5",
        "Season 6",
        "Season 7",
        "Season 8",
        "Season 9",
        "Season 10",
      ],
      required: true,
    },
  //   {
  //     id: "15",
  //     type: isEdit ? "lockedInput" : "inputBox",
  //     title: "Upload Movie Mp4 URL",
  //     endsWith: ".mp4",
  //     errorText: "File extension should be .mp4 only",
  //     name: "movieMp4_URL",
  //     required: true
  // },

    {
      id: "3",
      type: "image",
      title: "Season Thumbnail",
      subtitle: "(Resolution : 1080px x 1080px) *",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      name: "season_thumbnail",
      // required: true,
    },

    {
      id: "5",
      type: "button",
      title: isEdit ? "Edit" : "Create",
    },
  ]);
  useMemo(() => {
    const temp = formStructure;

    if (isEdit) {
      setView("create_new");
      temp[0]["type"] = "inputBox"
      temp[0]["disabled"] = true
      temp[1]["type"] = "inputBox"
      temp[1]["disabled"] = true


    } else {
      setView("view_all");
      temp[0]["type"] = "select"
      temp[0]["disabled"] = false
      temp[1]["type"] = "select"
      temp[1]["disabled"] = false


      setForm({});
    }
    // tempFormStruct.map((ele, j) => (temp[j] = ele));
    setFormStructure([...temp]);
  }, [isEdit]);
  // console.log(isEdit,"popo")
  // console.log(formStructure,"32563256")
  const message = useSelector((state) => state.layout.message);

  const season = useSelector((state) => state.webseries.season);
  const series = useSelector((state) => state.webseries.series);
  // console.log(season, "98989898");
  useMemo(() => {
    const data = new FormData();
    data.append("id", user?.id);
    // console.log(data, "3333");

    dispatch(all_series_list(data));
    dispatch(all_season_list(data));
  }, [user]);
  useMemo(() => {
    if (series?.statuscode == 200) {
      const temp = formStructure;
      temp[1]["options"] = series?.data.map((ele) => (ele?.series_type == form?.series_type) && ele.series_name).filter((e) => e);
      setFormStructure([...temp]);
    }
  }, [series , form?.series_type]);
  useMemo(() => {
    // console.log("testing22")
    if (season?.statuscode == 200) {
    // console.log("testing220")

      const temp = tableData;
      temp.tableBody = season?.data?.map((ele) => ({
        ...ele,
        series: ele?.series_name,
      }));
      setTableData({ ...temp });
    }
  }, [season]);

  const formTitle = isEdit ? "Edit Season" : "Create Season";
  const handleSubmit = (event) => {
    // console.log(form, "888");
    event.preventDefault();
    const temForm = form;
    temForm["tempseries"] = form?.["series"];
    temForm["series"] =  series?.data
    ?.filter((option) => form?.["series"] === option.series_name)
    .map((option) => option.id);
    // temForm["series"] = series?.data
    //   .map(
    //     (option) => form?.["series"]?.includes(option.series_name) && option.id
    //   )
    //   .filter((e) => e);
    setForm({
      ...temForm,
    });
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("user", user?.id);
    formStructure.map((element) => {
      if (
        element.type == "image" &&
        form?.[element.name] &&
        typeof form?.[element.name] != "string"
      ) {
        const temp = form;
        temp["temp" + element.name] = form?.[element.name];
        temp[element.name] = URL.createObjectURL(form?.[element.name]);

        setForm({
          ...temp,
        });
      }
    });

    if (isEdit) {
      dispatch(season_update(data));
    } else {
      dispatch(season_create(data));
    }
    setFlag(true);
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      const temp = tableData;
      if (isEdit) {
        temp.tableBody.map(
          (value, index) =>
            value.id == form.id && (temp.tableBody[index] = { ...form })
        );
      } else {
        temp.tableBody[temp.tableBody.length] = {
          id: temp.tableBody.length,
          ...form,
          edit: temp.tableBody.length,
        };
      }
      setTableData({ ...temp });
      setIsEdit(false);
      setForm({});
      setFlag(false);
      navigate("/Season/Season/", { state: { view: "view_all" } });
      setView("view_all");
      setTimeout(() => {
        const data = new FormData();
        data.append("id", user?.id);
        dispatch(all_season_list(data));
      }, 1000);
    } else {
      const tempForm = form;
      //   tempForm["season_thumbnail"] = form?.tempseason_thumbnail;

      tempForm["series"] = form?.tempseries;
      setForm({ ...tempForm });
    }
  }, [message]);
  return (
    <>
     { role !== "Distributor"&& <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        // access={rights?.["TV Shows"]?.["create"] == "true"}
        view={view}
        isEdit={isEdit}
        create_new={"/Season/CreateSeason/"}
        access={rights?.["Season"]?.["create"] == "true"}
        view_all={"/Season/Season/"}
        form={form}
      />}

      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Season/EditSeason/"}
        />
      )}
    </>
  );
}
